import React from "react";
import {NavLink, useNavigate} from "react-router-dom";

import logo from "../assets/logo.svg";
import { EmailButton } from "./EmailButton";
import classes from "./Header.module.scss";

export const Header = () => {
  const navigate = useNavigate();

  return (
    <>
      <header className={classes.topBar}>
        <div className={classes.top}>
          <NavLink to="/">
            <img
              width="182"
              height="50"
              style={{ cursor: "pointer" }}
              src={logo}
              alt="Logo"
              className={classes.headerLogo}
            />
          </NavLink>
          <nav className={classes.buttonsGroup}>
            <EmailButton onClick={() => navigate("/auth")} classes={[classes.button]} />
          </nav>
        </div>
      </header>
    </>
  );
};
