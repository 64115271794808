import isMobile from "is-mobile";
import React from "react";
import { NavLink } from "react-router-dom";

import Carousel from "./Carousel";
import InfoIcon from "../assets/info.svg";
import InDevIcon from "../assets/in-dev-icon.svg";
import FirstDiamond from "../assets/month-diamond.svg";
import SecondDiamond from "../assets/half_year-diamond.svg";
import ThirdDiamond from "../assets/year-diamond.svg";
import classNames from "classnames";
import classes from "./Prices.module.scss";
import SmartLook from "smartlook-client";
import { CarouselTypes, SubscriptionPlanTypes } from "common";

const mobileDevice = isMobile({ tablet: false, featureDetect: true });

const Prices = ({ hasButton }: { hasButton: boolean }) => {
  const registration = () => {
    SmartLook.navigation("/register");
    window.location.href = process.env.REACT_APP_REGISTRATION_URL as string;
  };

  const renderSubscriptionPlans = () => [
    <article
      className={classNames(
        classes.subscriptionPlan,
        classes.subscriptionPlanFirst,
      )}
      key={SubscriptionPlanTypes.Standard}
    >
      <img
        className={classes.subscriptionPlanBg}
        src={FirstDiamond}
        alt="Diamond"
      />
      <h2 className={classes.subscriptionPlanHeader}>Standard</h2>
      <p className={classes.subscriptionPlanPrice}>{129} Kč</p>
      <p className={classes.subscriptionPlanTerm}>1 měsíc</p>
      <div className={classes.line}></div>
      <ul className={classes.subscriptionPlanList}>
        <li>6000+ úkolů</li>
        <li>personalizační algoritmus</li>
        <li>interaktivní kurz pro předškolní přípravu</li>
        <li>základní analytický modul</li>
        <li>animované lekce ke každému tématu</li>
        <li>trenažer</li>
        <li>pokročilý analytický modul</li>
      </ul>
      <p className={classes.subscriptionPlanInfo}>
        <img src={InfoIcon} alt="Info" /> Cena je uvedena za 1 účastníka
      </p>
      <a
        href="#"
        onClick={registration}
        className={classes.subscriptionPlanBtn}
      >
        Vybrat
      </a>
    </article>,
    <article
      className={classNames(
        classes.subscriptionPlan,
        classes.subscriptionPlanSecond,
      )}
      key={SubscriptionPlanTypes.Premium}
    >
      <img
        className={classes.subscriptionPlanBg}
        src={SecondDiamond}
        alt="Diamond"
      />
      <span className={classes.subscriptionPlanDiscount}>Sleva 56%</span>
      <h2 className={classes.subscriptionPlanHeader}>Premium</h2>
      <p className={classes.subscriptionPlanPrice}>
        <span className={classes.subscriptionPlanPriceOld}>299</span>
        129 Kč
      </p>
      <p className={classes.subscriptionPlanTerm}>1 měsíc</p>
      <div className={classes.line}></div>
      <ul className={classes.subscriptionPlanList}>
        <li>6000+ úkolů</li>
        <li>personalizační algoritmus</li>
        <li>interaktivní kurz pro předškolní přípravu</li>
        <li>základní analytický modul</li>
        <li>animované lekce ke každému tématu</li>
        <li>trenažer</li>
        <li>pokročilý analytický modul </li>
      </ul>
      <p className={classes.subscriptionPlanInfo}>
        <img src={InfoIcon} alt="Info" /> Cena je uvedena za 1 účastníka
      </p>
      <a
        href="#"
        onClick={registration}
        className={classes.subscriptionPlanBtn}
      >
        Vybrat
      </a>
    </article>,
    <article
      className={classNames(
        classes.subscriptionPlan,
        classes.subscriptionPlanThird,
      )}
      key={SubscriptionPlanTypes.PremiumPlus}
    >
      <img
        className={classes.subscriptionPlanBg}
        src={ThirdDiamond}
        alt="Diamond"
      />
      <h2 className={classes.subscriptionPlanHeader}>Premium +</h2>
      <p className={classes.subscriptionPlanPrice}>{1099} Kč</p>
      <p className={classes.subscriptionPlanTerm}>6 měsíců</p>
      <div className={classes.line}></div>
      <ul className={classes.subscriptionPlanList}>
        <li>6000+ úkolů</li>
        <li>personalizační algoritmus</li>
        <li>interaktivní kurz pro předškolní přípravu</li>
        <li>základní analytický modul</li>
        <li>animované lekce ke každému tématu </li>
        <li>trenažer</li>
        <li>pokročilý analytický modul</li>
      </ul>
      <p className={classes.subscriptionPlanInfo}>
        <img src={InfoIcon} alt="Info" /> Cena je uvedena za 1 účastníka
      </p>
      <a
        href="#"
        onClick={registration}
        className={classes.subscriptionPlanBtn}
      >
        Vybrat
      </a>
    </article>,
  ];

  return (
    <section className={classes.pricePage}>
      <h2 className={classes.pricePageHeader}>Naše balíčky</h2>
      <div className={classes.pricePageCards}>
        {mobileDevice ? (
          <Carousel
            slides={renderSubscriptionPlans()}
            type={CarouselTypes.Mobile}
            hasControls
          />
        ) : (
          renderSubscriptionPlans()
        )}

        <span className={classes.inDevInfo}>
          <img src={InDevIcon} alt="" /> - připravujeme
        </span>
      </div>
      {hasButton && (
        <NavLink to="/">
          <button className={classes.pricePageBtn}>Hlavní stránka</button>
        </NavLink>
      )}
    </section>
  );
};

export default Prices;
