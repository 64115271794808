export enum CarouselTypes {
  Images = "images",
  Mobile = "mobile",
}

export enum SubscriptionPlanTypes {
  Demo = "DEMO",
  Standard = "STANDARD",
  Premium = "PREMIUM",
  PremiumPlus = "PREMIUM_PLUS",
}
