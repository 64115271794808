import classNames from "classnames";
import React, { useLayoutEffect, useRef } from "react";

import poster from "../assets/video-poster.png";
import { EmailButton } from "./EmailButton";
import classes from "./Platform.module.scss";
import SmartLook from "smartlook-client";

export const Platform = () => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useLayoutEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, [videoRef.current]);

  const registration = () => {
    SmartLook.navigation("/register");
    window.location.href = process.env.REACT_APP_REGISTRATION_URL as string;
  };

  return (
    <section className={classes.platform}>
      <div className={classNames(classes.platformContainer)}>
        <div className={classes.info}>
          <div className={classes.infoContainer}>
            <div className={classes.platformTitleContainer}>
              <h1
                className={classNames(classes.platformTitle, "visually-hidden")}
              >
                Online vzdělávací platforma
              </h1>
            </div>
            <div className={classes.infoListContainer}>
              <ul className={classes.infoList}>
                <li className={classes.infoListItem}>Pro děti 4-8 let</li>
                <li className={classes.infoListItem}>Příprava na školu</li>
                <li className={classes.infoListItem}>Doučování pro 1. třídu</li>
              </ul>
            </div>
          </div>
          <div className={classes.btnContainer}>
            <EmailButton
              onClick={registration}
              classes={[classes.registrationButton]}
            />

            {/* Will be added later
            <button className={classes.infoBtn}>Mateřské školky</button>
            */}
          </div>
        </div>
      </div>
      <video
        className={classes.video}
        ref={videoRef}
        autoPlay
        loop
        muted
        playsInline
        src="https://storage.googleapis.com/sharp-brain-assets/landing/landing-preview.mp4"
        poster={poster}
      ></video>
    </section>
  );
};
