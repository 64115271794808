import React from "react";

export const EmailButton = ({
  classes,
  onClick,
}: {
  classes: string[];
  onClick: () => void;
}) => {
  return (
    <>
      <button
        className={`${classes.join(" ")} default-button`}
        onClick={() => {
          onClick();
        }}
      >
        Začít zdarma
      </button>
    </>
  );
};
