import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import { CarouselIndicator, useIndicator } from "./CarouselIndicator";
import { EmblaCarouselType } from "embla-carousel";

import { ReactComponent as NextBtn } from "../assets/Features/Carousel/carousel-right.svg";
import { ReactComponent as PrevBtn } from "../assets/Features/Carousel/carousel-left.svg";
import classes from "./Carousel.module.scss";
import { CarouselTypes } from "common";

interface ICarouselItem {
  item: string;
  label: string;
}

interface ISlides {
  slides: ICarouselItem[] | React.ReactNode[];
  hasControls?: boolean;
  hasIndicators?: boolean;
  type: string;
}

const Carousel = ({ slides, hasControls, hasIndicators, type }: ISlides) => {
  const options = { startIndex: 1 };
  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  const { selectedIndex, onIndicatorClick } = useIndicator(emblaApi);

  const scrollPrev = useCallback(() => {
    emblaApi?.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    emblaApi?.scrollNext();
  }, [emblaApi]);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    onSelect(emblaApi);
    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
  }, [emblaApi, onSelect]);

  return (
    <div
      className={classNames(classes.carousel, {
        [classes.images]: type === CarouselTypes.Images,
        [classes.mobile]: type === CarouselTypes.Mobile,
      })}
    >
      <div className={classes.carouselContainer} ref={emblaRef}>
        <div className={classes.contentContainer}>
          {(slides as ICarouselItem[]).map((slide, index) => (
            <div
              className={classNames(classes.slide, {
                [classes.notSnapped]: index !== selectedIndex,
              })}
              key={index}
            >
              {type === CarouselTypes.Images && (
                <>
                  <img
                    className={classes.slideImg}
                    src={slide.item}
                    alt={slide.label}
                  />
                  <span className={classes.slideText}>{slide.label}</span>
                </>
              )}

              {type === CarouselTypes.Mobile && <>{slide}</>}
            </div>
          ))}
        </div>

        {hasIndicators && (
          <div className={classes.carouselIndicator}>
            {slides.map((_, index) => (
              <CarouselIndicator
                key={index}
                onClick={() => onIndicatorClick(index)}
                className={classNames(classes.carouselIndicatorItem, {
                  [classes.carouselIndicatorActive]: index === selectedIndex,
                })}
              />
            ))}
          </div>
        )}
      </div>
      {hasControls && (
        <>
          <button
            className={classNames(classes.prevBtn, {
              [classes.disabledBtn]: prevBtnDisabled,
            })}
            onClick={scrollPrev}
          >
            <PrevBtn />
          </button>
          <button
            className={classNames(classes.nextBtn, {
              [classes.disabledBtn]: nextBtnDisabled,
            })}
            onClick={scrollNext}
          >
            <NextBtn />
          </button>
        </>
      )}
    </div>
  );
};

export default Carousel;
