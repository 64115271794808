import "primeicons/primeicons.css";
import "./App.scss";
import React from "react";
import { Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import { Main } from "./pages/Main";

const LogicPage = React.lazy(() => import("./pages/LogicPage"));
const SkillsPage = React.lazy(() => import("./pages/SkillsPage"));
const JobPage = React.lazy(() => import("./pages/JobPage"));
const ContactsPage = React.lazy(() => import("./pages/ContactsPage"));
const PricePage = React.lazy(() => import("./pages/PricePage"));
const TermsPage = React.lazy(() => import("./pages/TermsPage"));
const PrivacyPage = React.lazy(() => import("./pages/PrivacyPage"));
const AuthPage = React.lazy(() => import("./pages/AuthPage"));

const App = () => (
  <ScrollToTop>
    <React.Suspense fallback="...">
      <Routes>
        <Route path="/" Component={Main} />
        <Route path="/logic" Component={LogicPage} />
        <Route path="/skills" Component={SkillsPage} />
        <Route path="/jobs" Component={JobPage} />
        <Route path="/contact" Component={ContactsPage} />
        <Route path="/pricing" Component={PricePage} />
        <Route path="/terms" Component={TermsPage} />
        <Route path="/privacy" Component={PrivacyPage} />
        <Route path="/auth" Component={AuthPage} />
      </Routes>
    </React.Suspense>
  </ScrollToTop>
);

export default App;
