import "./index.css";

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from 'react-router-dom';
import SmartLook from "smartlook-client";

import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

if (process.env.REACT_APP_IS_PROD === "true") {
  SmartLook.init("14de6d23a94cba1386db28d978bc6e92d1604f58", { region: "eu" });
}

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
