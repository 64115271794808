import axios from "axios";

export interface IFeedback {
  name: string;
  email: string;
  feedback: string;
}

export const createFeedback = async (dto: IFeedback) => {
  const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/v1/feedback`, dto);

  return data;
};
