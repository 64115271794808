import React, { useCallback, useEffect, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import { useIndicator } from "./CarouselIndicator";
import { EmblaCarouselType } from "embla-carousel";

import { ReactComponent as NextBtn } from "../assets/Features/Carousel/carousel-right.svg";
import { ReactComponent as PrevBtn } from "../assets/Features/Carousel/carousel-left.svg";
import { ReactComponent as Quote } from "../assets/Testimonials/quote.svg";
import classes from "./Testimonials.module.scss";
import classNames from "classnames";

const CarouselItems = [
  {
    id: 1,
    color: "#AADB49",
    author: "Od učitele",
    text: "Tento program doporučuji všem, kteří mají budoucí školáky. Jednou z hlavních výhod tohoto programu je jeho interaktivní a zábavný přístup ke vzdělávání. S takto skvěle řešenými  animovanými lekcemi jsem  se setkala poprvé.",
  },
  {
    id: 2,
    color: "#A189FF",
    author: "Od rodiče",
    text: "Líbí se mi, že děti se díky aplikaci učí nejen biflovat počty nazpaměť, ale skutečně chápat matematiku - například pomocí číselné řady.",
  },
  {
    id: 3,
    color: "#AADB49",
    author: "Od učitele",
    text: "Skvělá vzdělávací platforma, na které se mi líbí zapojení rodičů do učení, kteří společně s dětmi mohou procházet materiály, to je dost efektivní. Super vzdělávací nástroj pro rodiny. Doporučuji!",
  },
  {
    id: 4,
    color: "#A189FF",
    author: "Od rodiče",
    text: "Skvělá online platforma pro vzdělávání a rozvoj dětí. Moje děti ve věku 3,5 a 7 let rády splňují všechny úkoly, zejména jsou pro ně zajímavé vzdělávací animované lekce. Moje děti se perou o možnost splnit úkoly jako první.",
  },
  {
    id: 5,
    color: "#A189FF",
    author: "Od rodiče",
    text: "Tahle aplikace má přesně to, co rodiče potřebují. Já u dětí takto poznám, v čem mají přidat a čemu nerozumí.",
  },
];

export const Testimonials = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [emblaRef, emblaApi] = useEmblaCarousel();
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  const { selectedIndex } = useIndicator(emblaApi);

  const scrollPrev = useCallback(() => {
    emblaApi?.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    emblaApi?.scrollNext();
  }, [emblaApi]);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
    setActiveIndex(emblaApi?.selectedScrollSnap());
  }, []);

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    onSelect(emblaApi);
    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
  }, [emblaApi, onSelect]);

  return (
    <div className={classes.testimonialsContainer}>
      <section className={classes.testimonials}>
        <h2 className={classes.testimonialsTitle}>
          Zpětná vazba od našich uživatelů
        </h2>
        <article className={classes.testimonialsItem}>
          <span className={classes.quote}>
            <Quote style={{ fill: CarouselItems[activeIndex].color }} />
          </span>
          <h3
            className={classes.testimonialsAuthor}
            style={{ backgroundColor: CarouselItems[activeIndex].color }}
          >
            {CarouselItems[activeIndex].author}
          </h3>
          <div className={classes.testimonialsSliderContainer} ref={emblaRef}>
            <div className={classes.contentContainer}>
              {CarouselItems.map((item, index) => (
                <div
                  className={classNames(classes.slide, {
                    [classes.notSnapped]: index !== selectedIndex,
                  })}
                  key={index}
                >
                  <p className={classes.testimonialsText}>{item.text}</p>
                  <span className={classes.testimonialsItemNum}>
                    {item.id < 10 ? "0" + item.id : item.id}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </article>
      </section>
      <div className={classes.controlsContainer}>
        <button
          className={classNames(classes.prevBtn, {
            [classes.disabledBtn]: prevBtnDisabled,
          })}
          onClick={scrollPrev}
        >
          <PrevBtn />
        </button>
        <span>
          {activeIndex + 1} z {CarouselItems.length}
        </span>
        <button
          className={classNames(classes.nextBtn, {
            [classes.disabledBtn]: nextBtnDisabled,
          })}
          onClick={scrollNext}
        >
          <NextBtn />
        </button>
      </div>
    </div>
  );
};
