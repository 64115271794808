import classNames from "classnames";
import React, { useState } from "react";

import CheckIcon from "../assets/check-white-lg.svg";
import { createFeedback } from "../services/feedback.service";
import classes from "./Form.module.scss";

export const Form = () => {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [feedback, setFeedback] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasSent, setHasSent] = useState<boolean>(false);

  const handleClick = async () => {
    if (!name || !email || !feedback) {
      return;
    }

    try {
      setIsLoading(true);

      await createFeedback({
        name,
        email,
        feedback,
      });
    } finally {
      setName("");
      setEmail("");
      setFeedback("");
      setIsLoading(false);
      setHasSent(true);
    }

    setTimeout(() => {
      setHasSent(false);
    }, 3000);
  };

  return (
    <div className={classes.form}>
      <div className={classes.inputContainer}>
        <div className={classes.formHeaderContainer}>
          <h2 className={classes.formHeader}>Máte dotaz nebo zpětnou vazbu?</h2>
        </div>
        <div>
          <div className="w-12 pb-3 flex">
            <input
              className={classes.input}
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Vaše jméno"
            />
          </div>
          <div className="w-12 pb-3 flex">
            <input
              className={classes.input}
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Váš e-mail"
              required
            />
          </div>
          <div className="w-12 flex">
            <textarea
              className={classNames(classes.input, classes.textarea)}
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              placeholder="Zanechte vzkaz"
            ></textarea>
          </div>
        </div>
        <button
          className={classNames(classes.button, "default-button", {
            [classes.hasSent]: hasSent,
          })}
          disabled={!name || !email || !feedback || isLoading}
          onClick={handleClick}
        >
          {hasSent ? (
            <span className={classes.hasSentText}>
              <img src={CheckIcon} alt="Check" /> Odesláno
            </span>
          ) : (
            "Odeslat"
          )}
        </button>
      </div>
    </div>
  );
};
