import React from "react";

import classes from "./Trends.module.scss";

export const Trends = () => (
  <section className={classes.trendsContainer}>
    <div className={classes.trends}>
      <div className={classes.trendsBlock}>
        <div className={classes.trendsHeaderContainer}>
          <h2 className={classes.trendsHeader}>
            Tvoříme základ pro budoucí úspěch dětí.
          </h2>
        </div>

        <ul className={classes.trendItemsList}>
          <li className={classes.trendItemsListItem}>#Logika</li>
          <li className={classes.trendItemsListItem}>#Matematika</li>
          <li className={classes.trendItemsListItem}>#Vnímání</li>
          <li className={classes.trendItemsListItem}>#Paměť</li>
          <li className={classes.trendItemsListItem}>#SvětKolemNás</li>
          <li className={classes.trendItemsListItem}>#ProstorovéMyšlení</li>
        </ul>
      </div>
    </div>
  </section>
);
