import classNames from "classnames";
import React from "react";

import Img1 from "../assets/PlatformInfo/step1.png";
import Img2 from "../assets/PlatformInfo/step2.png";
import Img3 from "../assets/PlatformInfo/step3.png";
import Img4 from "../assets/PlatformInfo/step4.png";
import Img5 from "../assets/PlatformInfo/step5.png";

import classes from "./PlatformInfo.module.scss";
import { EmailButton } from "./EmailButton";
import SmartLook from "smartlook-client";

export const PlatformInfo = () => {
  const registration = () => {
    SmartLook.navigation("/register");
    window.location.href = process.env.REACT_APP_REGISTRATION_URL as string;
  };

  return (
    <section className={classes.platformInfo}>
      <div className={classes.headerContainer}>
        <h2 className={classes.platformInfoHeader}>
          Proč zvolit výuku se Sharp Brain?
        </h2>
      </div>

      <div className={classes.container}>
        <div className={classNames(classes.stepTextBox, classes.stepTextBox1)}>
          <p className={classes.stepNumber}>01</p>
          <h3 className={classes.stepTitle}>Žádný stres a žádný tlak</h3>
          <p className={classes.stepDescription}>
            Naše dětská online vzdělávací platforma je navržena tak, aby bylo
            učení příjemné a bez stresu. Každá lekce je strukturovaná tak, aby
            dítě postupovalo svým tempem a učilo se nové věci s radostí a
            zvědavostí.
          </p>
        </div>
        <div className={classNames(classes.stepImgBox, classes.stepImgBox1)}>
          <img className={classes.stepImg} src={Img1} alt="" />
        </div>

        <div className={classNames(classes.stepImgBox, classes.stepImgBox2)}>
          <img className={classes.stepImg} src={Img2} alt="" />
        </div>
        <div className={classNames(classes.stepTextBox, classes.stepTextBox2)}>
          <p className={classes.stepNumber}>02</p>
          <h3 className={classes.stepTitle}>
            Individuální program pro každého
          </h3>
          <p className={classes.stepDescription}>
            Díky pokročilým algoritmům personalizace jsme schopni nabídnout
            každému uživateli unikátní vzdělávací obsah, který maximálně
            podporuje jeho rozvoj a motivaci k učení. Tento přístup umožňuje
            dětem učit se efektivně a v souladu s jejich individuálním tempem a
            zájmy.
          </p>
        </div>

        <div className={classNames(classes.stepTextBox, classes.stepTextBox3)}>
          <p className={classes.stepNumber}>03</p>
          <h3 className={classes.stepTitle}>
            Pohodlí domova pro efektivní výsledek
          </h3>
          <p className={classes.stepDescription}>
            Domácí prostředí je pro mnoho dětí přirozeně uklidňující, což může
            zvýšit jejich schopnost soustředit se a absorbovat nové informace
            bez zbytečného stresu nebo rozptylování, jaké může nastat v tradiční
            třídní atmosféře.
          </p>
        </div>
        <div className={classNames(classes.stepImgBox, classes.stepImgBox3)}>
          <img className={classes.stepImg} src={Img3} alt="" />
        </div>

        <div className={classNames(classes.stepImgBox, classes.stepImgBox4)}>
          <img className={classes.stepImg} src={Img4} alt="" />
        </div>
        <div className={classNames(classes.stepTextBox, classes.stepTextBox4)}>
          <p className={classes.stepNumber}>04</p>
          <h3 className={classes.stepTitle}>
            Poskytujeme expertízu a šetříme váš čas
          </h3>
          <p className={classes.stepDescription}>
            Naše platforma poskytuje odbornou expertízu a šetří váš čas tím, že
            nabízí efektivně strukturované vzdělávací materiály. Díky tomu
            můžete strávit méně času plánováním a více času podporováním
            osobního rozvoje vašich dětí.
          </p>
        </div>

        <div className={classNames(classes.stepTextBox, classes.stepTextBox5)}>
          <p className={classes.stepNumber}>05</p>
          <h3 className={classes.stepTitle}>
            Nabízíme rodičům jednoduchou analýzu
          </h3>
          <p className={classes.stepDescription}>
            Náš systém analyzuje profil každého uživatele, identifikuje jeho
            silné a slabé stránky, a pomáhá tak rodičům lépe porozumět
            vzdělávacím potřebám svých dětí. Tento přístup umožňuje cíleně
            podporovat oblasti, ve kterých dítě potřebuje zlepšení, zatímco
            zároveň posiluje již dobře rozvinuté dovednosti.
          </p>
        </div>
        <div className={classNames(classes.stepImgBox, classes.stepImgBox5)}>
          <img className={classes.stepImg} src={Img5} alt="" />
        </div>
      </div>

      <div className={classes.btnContainer}>
        <EmailButton onClick={registration} classes={[classes.button]} />
      </div>
    </section>
  );
};
