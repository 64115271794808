import React from "react";

import CarouselItem1 from "../assets/Features/Carousel/AnimLekce.png";
import CarouselItem2 from "../assets/Features/Carousel/Lekce.png";
import CarouselItem3 from "../assets/Features/Carousel/Sbirky.png";
import CarouselItem4 from "../assets/Features/Carousel/Analyzy.png";
import CarouselItem5 from "../assets/Features/Carousel/DetskyProfil.png";
import CarouselItem6 from "../assets/Features/Carousel/Trenazer.png";

import { CarouselTypes } from "../common";

import classes from "./Features.module.scss";
import Carousel from "./Carousel";

const CarouselItems = [
  { item: CarouselItem3, label: "Interaktivní sbírky" },
  { item: CarouselItem1, label: "Animované lekce" },
  { item: CarouselItem2, label: "Personalizační algoritmus" },
  { item: CarouselItem4, label: "Sekce “Pro rodiče”" },
  { item: CarouselItem5, label: "Dětský Profil" },
  { item: CarouselItem6, label: "Trenažér" },
];

export const Features = () => {
  return (
    <section className={classes.features}>
      <h2 className={classes.featuresTitle}>Co je uvnitř?</h2>
      <div className={classes.featuresCarousel}>
        <Carousel
          slides={CarouselItems}
          hasControls
          hasIndicators
          type={CarouselTypes.Images}
        />
      </div>
    </section>
  );
};
