import { Platform } from "components/Platform";
import React from "react";

import { Feedback } from "../components/Feedback";
import { Features } from "components/Features";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { PlatformInfo } from "../components/PlatformInfo";
import { Testimonials } from "../components/Testimonials";
import { Skills } from "../components/Skills";
import { Trends } from "../components/Trends";
import Prices from "components/Prices";

export const Main = () => {
  return (
    <>
      <Header />
      <div className="body">
        <Platform />
        <Trends />
        <PlatformInfo />
        <Features />
        <Skills />
        <Testimonials />
        <Prices hasButton={false} />
        <Feedback />
        <Footer />
      </div>
    </>
  );
};
