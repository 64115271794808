import classNames from "classnames";
import React from "react";

import skill1 from "../assets/Skills/skills1.png";
import skill2 from "../assets/Skills/skills2.png";
import skill3 from "../assets/Skills/skills3.png";
import classes from "./Skills.module.scss";

export const Skills = () => (
  <section className={classes.skillsContainer}>
    <div className={classes.skillsHeaderContainer}>
      <h2 className={classes.skillsHeader}>
        V čem je učení se Sharp Brain jiné?
      </h2>
    </div>
    <div className={classes.itemsContainer}>
      <figure className={classNames(classes.skillsItem, classes.skillsItem1)}>
        <div className={classNames(classes.imageContainer)}>
          <img className={classes.itemImage} src={skill1} alt="" />
        </div>
        <div className={classes.itemContainer}>
          <h3 className={classes.itemHeader}>Technologie</h3>
          <figcaption className={classes.itemText}>
            Vyvinuli jsme unikátní algoritmus, který vyhodnocuje každého
            uživatele individuálně a přizpůsobuje se jeho specifickým potřebám.
          </figcaption>
        </div>
      </figure>
      <figure className={classNames(classes.skillsItem, classes.skillsItem2)}>
        <div className={classNames(classes.imageContainer, "")}>
          <img className={classes.itemImage} src={skill2} alt="" />
        </div>
        <div className={classes.itemContainer}>
          <h3 className={classes.itemHeader}>Zkušenost</h3>
          <figcaption className={classes.itemText}>
            Po více než 40 letech pedagogické praxe jsme pečlivě vybrali metody
            zohledňující nejen maximální efektivitu učebního procesu, ale i
            osobnost dítěte.
          </figcaption>
        </div>
      </figure>
      <figure className={classNames(classes.skillsItem, classes.skillsItem3)}>
        <div className={classNames(classes.imageContainer)}>
          <img className={classes.itemImage} src={skill3} alt="" />
        </div>
        <div className={classes.itemContainer}>
          <h3 className={classes.itemHeader}>Jednoduchost</h3>
          <figcaption className={classes.itemText}>
            Nabízíme online intuitivní analytický systém, s nímž získáte přehled
            o celém vzdělávacím procesu kdykoli a kdekoli.
          </figcaption>
        </div>
      </figure>
    </div>
  </section>
);
