import React from "react";

import Mascot from "../assets/Feedback/feedback-mascot.svg";
import classes from "./Feedback.module.scss";
import { Form } from "./Form";

export const Feedback = () => (
  <section className={classes.feedback}>
    <div className={classes.feedbackContainer}>
      <div className={classes.feedbackBg}>
        <img src={Mascot} alt="" />
      </div>

      <div className={classes.formContainer}>
        <Form />
      </div>
    </div>
  </section>
);
